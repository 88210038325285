<template>
  <div id="users-audits">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <h5>Nombre Inmobiliaria</h5>
            <b-row>
              <b-col xl="3" sm="12">
                <b-form-input id="name"
                  v-model="nameAgency"
                  name="name"
                  disabled />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <agencies-audits-list :audits="audits"></agencies-audits-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import AgenciesAuditsList from '../agencies/components/AgenciesAuditsList'

  export default{
    name: 'AgenciesAuditsView',
    components: {AgenciesAuditsList},
    props: ['audits'],
    data() {
      return {
      }
    },
    computed: {
      nameAgency() {
        return this.audits ? this.audits.name : ''
      }
    }
  }
</script>